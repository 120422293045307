import {
  Box,
  Button,
  Description,
  Dropdown,
  Heading,
  Icon,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { useState } from 'react'
import { AddAuthenticationDrawer } from 'admin/components/AddAuthenticationDrawer'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { DataTable, DateTime } from 'shared/components/DataTable'
import { Loading } from 'shared/components/Loading'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import {
  AddVaultForwardConfigAuthentication,
  VaultForwardConfig,
  VaultForwardConfigAuthentication,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'

interface EditMerchantAccountVaultForwardingAuthenticationPageProps {
  title: string
  merchantAccount: MerchantAccount | undefined
  config?: VaultForwardConfig
  authentications: Partial<CollectionResponse<VaultForwardConfigAuthentication>>
  definitions?: Partial<CollectionResponse<VaultForwardDefinition>>
  create: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    AddVaultForwardConfigAuthentication,
    unknown
  >
}

const EditMerchantAccountVaultForwardingAuthenticationPage = ({
  title,
  merchantAccount,
  config,
  authentications,
  definitions,
  create,
}: EditMerchantAccountVaultForwardingAuthenticationPageProps) => {
  const [openAddAuthenticationDrawer, setOpenAddAuthenticationDrawer] =
    useState(false)
  const hasWritePermission = useResourcePermission(
    Resource.vaultForwardAuthentications,
    AccessLevel.write
  )

  const columns: Array<ColumnDef<VaultForwardConfigAuthentication>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 90,
      cell: ({ row }) => <Text truncate>{row.original.displayName}</Text>,
    },
    {
      header: 'Authentication method',
      accessorKey: 'label',
      size: 90,
      cell: ({ row }) => <Text>{row.original.label}</Text>,
    },
    {
      header: 'UUID',
      accessorKey: 'type',
      size: 140,
      cell: ({ row }) => <Text>{row.original.id}</Text>,
    },
    {
      header: 'Creator',
      size: 60,
      cell: ({ row }) => (
        <Description>
          <Description.Text>{row.original.creator.name}</Description.Text>
          <Description.SubText>
            {row.original.creator.emailAddress}
          </Description.SubText>
        </Description>
      ),
    },
    {
      id: 'configured',
      accessorKey: 'createdAt',
      size: 50,
      header: () => <Text>Configured</Text>,
      cell: ({ getValue }) => {
        const createdAt =
          getValue<VaultForwardConfigAuthentication['createdAt']>()
        return <DateTime value={createdAt} />
      },
    },
    {
      id: 'actions',
      accessorKey: 'id',
      size: 45,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: () => (
        <Box textAlign="center">
          <Dropdown>
            <Dropdown.Trigger asChild>
              <Dropdown.Button variant="tertiary" size="small" disabled>
                <Icon name="more-horizontal" />
              </Dropdown.Button>
            </Dropdown.Trigger>
            <Dropdown.Content align="end">
              <Dropdown.Item opensInModal onSelect={() => {}}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item opensInModal onSelect={() => {}}>
                Delete
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </Box>
      ),
    },
  ]

  return (
    <>
      <MerchantPagesLayout
        title={title}
        breadcrumbs={[
          { title: 'Merchants', url: pathTo.merchants },
          { title: merchantAccount?.displayName || '' },
          {
            title: 'Vault Forwarding',
            url:
              merchantAccount?.id &&
              pathTo.vaultForwarding(merchantAccount?.id),
          },
          { title: config?.displayName || '' },
        ]}
        actions={
          <Button
            disabled={!hasWritePermission}
            variant="primary"
            onClick={() => setOpenAddAuthenticationDrawer(true)}
          >
            <Icon name="add-plus" />
            Add authentication
          </Button>
        }
      >
        {!config ? (
          <Loading />
        ) : (
          <Stack gap={32}>
            <Heading as="h2">{config?.displayName} authentication</Heading>
            <DataTable data={authentications} columns={columns} />
          </Stack>
        )}
      </MerchantPagesLayout>
      <AddAuthenticationDrawer
        open={openAddAuthenticationDrawer}
        onClose={() => setOpenAddAuthenticationDrawer(false)}
        create={create}
        definition={definitions?.items?.find(
          ({ id }) => id === config?.definitionId
        )}
        config={config}
      />
    </>
  )
}

export default EditMerchantAccountVaultForwardingAuthenticationPage
