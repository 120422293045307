import { Badge, Box, Flex, Icon, Skeleton, Text } from '@gr4vy/poutine-react'

export interface TimestampLabelProps {
  date: string | null
  time: string | null
}

export const TimestampLabel = ({ date, time }: TimestampLabelProps) => {
  return (
    <Flex color="gray80" alignItems="center" gap={4}>
      <Icon name="clock" size="small" />

      <Box>
        <Text as="span">Last updated </Text>
        <DateTime dateTime={date} />
        <Text as="span"> at </Text>
        <DateTime dateTime={time} />
      </Box>
      <Badge variant="subtle">
        <Text color="gray80">UTC</Text>
      </Badge>
    </Flex>
  )
}

const DateTime = ({ dateTime }: { dateTime: string | null }) =>
  dateTime ? (
    <Text as="span" fontWeight="bold">
      {dateTime}
    </Text>
  ) : (
    <TimestampSkeleton />
  )

const TimestampSkeleton = () => (
  <Skeleton
    display="inline-flex"
    style={{ verticalAlign: 'middle' }}
    width={64}
  >
    <Skeleton.Paragraph lines={1} style={{ width: '100%' }} />
  </Skeleton>
)
