import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Navigate, useParams } from 'react-router-dom'
import { pathTo } from 'admin/paths'
import {
  GET_MERCHANT_ACCOUNT,
  LIST_VAULT_FORWARD_CONFIG,
  LIST_VAULT_FORWARD_DEFINITIONS,
} from 'shared/constants/query-keys'
import { getMerchantAccount } from 'shared/services/merchant-accounts'
import {
  AddVaultForwardConfigAuthentication,
  addVaultForwardConfigAuthentication,
  getVaultForwardConfig,
  listVaultForwardDefinitions,
} from 'shared/services/vault-forward'
import { handleSuccessResponse } from 'shared/utils/handleSuccessResponse'
import { showErrorMessage } from 'shared/utils/showMessage'
import View from './EditMerchantAccountVaultForwardingAuthenticationPage'

const EditMerchantAccountVaultForwardingPage = ({
  title,
}: {
  title: string
}) => {
  const queryClient = useQueryClient()
  const { merchantAccountId, id } = useParams() as {
    merchantAccountId: string
    id: string
  }

  const { data: merchantAccount } = useQuery({
    queryKey: [GET_MERCHANT_ACCOUNT, merchantAccountId],
    queryFn: () => getMerchantAccount(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const { data: config, isSuccess: hasLoadedConfig } = useQuery({
    queryKey: [LIST_VAULT_FORWARD_CONFIG, merchantAccountId],
    queryFn: () => getVaultForwardConfig({ merchantAccountId, id }),
    enabled: !!merchantAccountId,
  })

  const { data: definitions, isSuccess: hasLoadedDefinitions } = useQuery({
    queryKey: [LIST_VAULT_FORWARD_DEFINITIONS, merchantAccountId],
    queryFn: () => listVaultForwardDefinitions(merchantAccountId),
    enabled: !!merchantAccountId,
  })

  const invalidateVaultForwardConfig = () =>
    queryClient.invalidateQueries({ queryKey: [LIST_VAULT_FORWARD_CONFIG] })

  const create = useMutation({
    mutationFn: ({
      merchantAccountId,
      id,
      ...rest
    }: AddVaultForwardConfigAuthentication) =>
      addVaultForwardConfigAuthentication({
        merchantAccountId,
        id,
        ...rest,
      }),
    onSuccess: () => {
      handleSuccessResponse(
        'Authentication method successfully added.',
        invalidateVaultForwardConfig
      )()
    },
    onError: (error) => {
      const { message } = (error as AxiosError).response?.data as {
        code: string
        message: string
      }
      showErrorMessage(`Unable to add the method. ${message}`)
    },
  })

  const hasConfigurableAuthentications = !!definitions?.items?.find(
    (definition) => definition.id === config?.definitionId
  )?.authentications?.length

  if (
    hasLoadedConfig &&
    hasLoadedDefinitions &&
    merchantAccount?.id &&
    !hasConfigurableAuthentications
  ) {
    return <Navigate replace to={pathTo.vaultForwarding(merchantAccount.id)} />
  }

  return (
    <View
      title={title}
      merchantAccount={merchantAccount}
      config={config}
      authentications={{ items: config?.authentications }}
      definitions={definitions}
      create={create}
    />
  )
}

export default EditMerchantAccountVaultForwardingPage
