import { Container, Stack } from '@gr4vy/poutine-react'
import { Helmet } from 'react-helmet'
import { DataVisualization } from 'home/components/DataVisualization'
import { Tables } from 'home/components/Tables'
import { TopSection } from 'home/components/TopSection'
import { HomeFilters } from 'home/constants/filter'
import { useFilters } from 'shared/hooks'
import { useGettingStarted } from 'transactions/hooks/use-getting-started'

type HomePageProps = {
  title: string
}

const HomePage = ({ title }: HomePageProps) => {
  const { showGettingStarted, hasTransactions } = useGettingStarted()
  const [filters, setFilters] = useFilters<HomeFilters>({
    currency: 'USD',
    filterBy: 'volume',
    period: '7-days',
    limit: 10,
  })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Stack flexDirection="column" paddingY={16} gap={32}>
          <TopSection
            showGettingStarted={showGettingStarted}
            hasTransactions={hasTransactions}
          />
          <DataVisualization
            showGettingStarted={showGettingStarted}
            filters={filters}
            setFilters={setFilters}
          />
          <Tables filters={filters} setFilters={setFilters} />
        </Stack>
      </Container>
    </>
  )
}

export default HomePage
