import { useQuery } from '@tanstack/react-query'
import { getDefinition } from 'connections/services'
import { TransactionSummary } from 'transactions/services'

const PAYMENT_SERVICE_DEFINITION = 'payment-service-definition'

export const useTransactionDetails = (transaction: TransactionSummary) => {
  const {
    amount,
    capturedAmount: transactionCapturedAmount,
    refundedAmount,
    status,
  } = transaction
  const capturedAmount = transactionCapturedAmount || amount
  const refundableAmount = capturedAmount - refundedAmount

  let captureSupported = false
  const captureEnabled =
    status === 'authorization_succeeded' && !!capturedAmount

  let refundSupported = false
  let partialRefundSupported
  const refundEnabled = status === 'capture_succeeded' && refundableAmount > 0

  let voidSupported
  const voidEnabled = status === 'authorization_succeeded' && amount > 0

  const paymentServiceDefinitionId =
    transaction?.paymentService?.paymentServiceDefinitionId

  const definition = useQuery({
    queryKey: [PAYMENT_SERVICE_DEFINITION, paymentServiceDefinitionId],
    queryFn: () => getDefinition(paymentServiceDefinitionId as string),
    // Only run this query if either refund or void is enabled
    enabled:
      !!paymentServiceDefinitionId &&
      (captureEnabled || refundEnabled || voidEnabled),
  })

  if (definition.isSuccess) {
    captureSupported = !!definition.data?.supportedFeatures?.delayedCapture
    refundSupported = !!definition.data?.supportedFeatures?.refunds
    partialRefundSupported =
      !!definition?.data?.supportedFeatures?.partialRefunds
    voidSupported = !!definition.data?.supportedFeatures?.void
  }

  return {
    captureSupported,
    captureEnabled,
    refundSupported,
    partialRefundSupported,
    refundEnabled,
    voidSupported,
    voidEnabled,
    definition,
  }
}
