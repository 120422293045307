import { Tooltip, Flex, TextLine, ButtonLink } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Buyer } from 'buyers/services/buyers'
import { getBuyerName } from 'buyers/utils'
import {
  ColumnDef,
  DataTable,
  TimeDate,
  DataTableProps,
} from 'shared/components/DataTable'
import { pathTo } from 'shared/paths/buyers'
import { QueryResult } from 'shared/services/client'

export type BuyersTableProps = Pick<
  DataTableProps<Buyer>,
  'pagination' | 'columns'
> & { page: QueryResult<Buyer> }

export const buyersColumns: Array<ColumnDef<Buyer>> = [
  {
    header: 'Name',
    accessorKey: 'displayName',
    size: 180,
    cell: ({ row }) => {
      return <TextLine display="block">{getBuyerName(row.original)}</TextLine>
    },
  },
  {
    header: 'Email',
    accessorFn: ({ billingDetails }) => {
      return billingDetails?.emailAddress ?? '-'
    },
    size: 216,
    cell: ({ getValue }) => {
      return <TextLine>{getValue() as string}</TextLine>
    },
  },
  {
    header: 'Merchant ID',
    accessorKey: 'externalIdentifier',
    size: 246,
    cell: ({ getValue }) => {
      return <TextLine>{getValue<Buyer['externalIdentifier']>()}</TextLine>
    },
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    size: 108,
    cell: ({ getValue }) => {
      const createdAt = getValue<Buyer['createdAt']>()
      return <TimeDate value={createdAt} />
    },
  },
  {
    id: 'actions',
    header: '',
    size: 64,
    cell: function ActionsCell({ row }) {
      const buyer = row.original
      const { merchantAccountId } = useParams() as { merchantAccountId: string }
      return buyer ? (
        <Flex justifyContent="flex-end">
          <Tooltip content="Go to buyer">
            <ButtonLink
              variant="tertiary"
              size="small"
              href={pathTo.buyer(merchantAccountId, buyer?.id)}
            />
          </Tooltip>
        </Flex>
      ) : null
    },
  },
]

const BuyersTable = ({ page, pagination, columns }: BuyersTableProps) => {
  const { data, isLoading: loading } = page

  return (
    <DataTable
      data={data}
      loading={loading}
      columns={columns ?? buyersColumns}
      pagination={pagination}
    />
  )
}

export default BuyersTable
