import { Navigate, useParams } from 'react-router-dom'
import {
  CONNECTIONS_CATALOG,
  CONNECTIONS_CONFIGURED,
  CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE,
  CONNECTION_ANTI_FRAUD_SERVICE,
  CONNECTION_ANTI_FRAUD_SIFT_SERVICE,
  CONNECTION_APPLE_PAY,
  CONNECTION_GOOGLE_PAY,
  CONNECTION_PAYMENT_SERVICE,
  CONNECTION_SELECT_APPLE_PAY,
  CONNECTION_SELECT_GOOGLE_PAY,
  pathTo,
  CONNECTIONS_PATH,
  CONNECTION_ANTI_FRAUD_FORTER_SERVICE,
  CONNECTION_SELECT_CLICK_TO_PAY,
  CONNECTION_CLICK_TO_PAY,
  CONNECTION_GIFT_CARD_SERVICE,
  CONNECTION_ANTI_FRAUD_KOUNT_SERVICE,
  CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE,
} from 'shared/paths/connections'
import { Resource } from 'shared/permissions/constants'
import {
  PaymentServiceAddPage,
  PaymentServiceOverviewPage,
  PaymentServiceCredentialsPage,
  PaymentServiceRegionDetailsPage,
  PaymentService3DSecurePage,
  ConnectionsCatalogPage,
  ConnectionsConfiguredPage,
  DigitalWalletsActivateApplePayPage,
  DigitalWalletsActivateGooglePayPage,
  DigitalWalletsActivateClickToPayPage,
  GooglePayOverview,
  ApplePayDomains,
  ApplePayOverview,
  ApplePayCertificates,
  AntiFraudServiceAddPage,
} from './pages'
import {
  CybersourceCredentials,
  CybersourceOverview,
} from './pages/AntiFraudServiceEditPage/Cybersource'
import {
  ForterCredentials,
  ForterMerchantInfo,
  ForterOverview,
} from './pages/AntiFraudServiceEditPage/Forter'
import {
  KountCredentials,
  KountManualReviews,
  KountOverview,
} from './pages/AntiFraudServiceEditPage/Kount'
import {
  SiftCredentials,
  SiftOverview,
  SiftMerchantInfo,
} from './pages/AntiFraudServiceEditPage/Sift'
import {
  SimulatorOverview,
  SimulatorCredentials,
} from './pages/AntiFraudServiceEditPage/Simulator'
import { ClickToPayOverview } from './pages/DigitalWalletsEditPage'
import GiftCardServiceAddPage from './pages/GiftCardServiceAddPage'
import { GiftCardServiceCredentialsPage } from './pages/GiftCardServiceEditPage/GiftCardServiceCredentialsPage'
import { GiftCardServiceOverviewPage } from './pages/GiftCardServiceEditPage/GiftCardServiceOverviewPage'

export default [
  {
    title: 'Add Apple Pay',
    key: 'digitalwallet.apple.add',
    path: CONNECTION_SELECT_APPLE_PAY,
    // @todo api does not currently return a service definition
    component: DigitalWalletsActivateApplePayPage,
    menu: false,
  },
  {
    title: 'Add Google Pay',
    key: 'digitalwallet.google.add',
    path: CONNECTION_SELECT_GOOGLE_PAY,
    // @todo api does not currently return a service definition
    component: DigitalWalletsActivateGooglePayPage,
    menu: false,
  },
  {
    title: 'Add Click To Pay',
    key: 'digitalwallet.clicktopay.add',
    path: CONNECTION_SELECT_CLICK_TO_PAY,
    // @todo api does not currently return a service definition
    component: DigitalWalletsActivateClickToPayPage,
    menu: false,
  },
  {
    title: 'Add Payment Service',
    key: 'payment-services.add',
    path: CONNECTION_PAYMENT_SERVICE,
    component: PaymentServiceAddPage,
    menu: false,
  },
  {
    title: 'Add Anti-Fraud Service',
    key: 'anti-fraud-service.activate',
    path: CONNECTION_ANTI_FRAUD_SERVICE,
    component: AntiFraudServiceAddPage,
    menu: false,
  },
  {
    title: 'Edit Cybersource Service',
    key: 'anti-fraud-service.cybersource',
    path: CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE,
    component: function CybersourceRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={`${pathTo.editConnectionAntiFraudCybersourceService(
            merchantAccountId,
            id
          )}/overview`}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Edit Cybersource Overview',
    key: 'anti-fraud-service.cybersource-overview',
    path: `${CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE}/overview`,
    component: CybersourceOverview,
    menu: false,
  },
  {
    title: 'Edit Cybersource Credentials',
    key: 'anti-fraud-service.cybersource-credentials',
    path: `${CONNECTION_ANTI_FRAUD_CYBERSOURCE_SERVICE}/credentials`,
    component: CybersourceCredentials,
    menu: false,
  },
  {
    title: 'Edit Sift Service',
    key: 'anti-fraud-service.sift',
    path: CONNECTION_ANTI_FRAUD_SIFT_SERVICE,
    component: function SiftRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={`${pathTo.editConnectionAntiFraudSiftService(
            merchantAccountId,
            id
          )}/overview`}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Edit Sift Overview',
    key: 'anti-fraud-service.sift-overview',
    path: `${CONNECTION_ANTI_FRAUD_SIFT_SERVICE}/overview`,
    component: SiftOverview,
    menu: false,
  },
  {
    title: 'Edit Sift Credentials',
    key: 'anti-fraud-service.sift-credentials',
    path: `${CONNECTION_ANTI_FRAUD_SIFT_SERVICE}/credentials`,
    component: SiftCredentials,
    menu: false,
  },
  {
    title: 'Edit Sift Merchant Information',
    key: 'anti-fraud-service.sift-merchant-info',
    path: `${CONNECTION_ANTI_FRAUD_SIFT_SERVICE}/merchant-info`,
    component: SiftMerchantInfo,
    menu: false,
  },
  {
    title: 'Edit Forter Service',
    key: 'anti-fraud-service.forter',
    path: CONNECTION_ANTI_FRAUD_FORTER_SERVICE,
    component: function ForterRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={`${pathTo.editConnectionAntiFraudForterService(
            merchantAccountId,
            id
          )}/overview`}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Edit Forter Overview',
    key: 'anti-fraud-service.forter-overview',
    path: `${CONNECTION_ANTI_FRAUD_FORTER_SERVICE}/overview`,
    component: ForterOverview,
    menu: false,
  },
  {
    title: 'Edit Forter Credentials',
    key: 'anti-fraud-service.forter-credentials',
    path: `${CONNECTION_ANTI_FRAUD_FORTER_SERVICE}/credentials`,
    component: ForterCredentials,
    menu: false,
  },
  {
    title: 'Edit Forter Merchant Information',
    key: 'anti-fraud-service.forter-merchant-info',
    path: `${CONNECTION_ANTI_FRAUD_FORTER_SERVICE}/merchant-info`,
    component: ForterMerchantInfo,
    menu: false,
  },
  {
    title: 'Edit Kount Service',
    key: 'anti-fraud-service.kount',
    path: CONNECTION_ANTI_FRAUD_KOUNT_SERVICE,
    component: function KountRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={`${pathTo.editConnectionAntiFraudKountService(
            merchantAccountId,
            id
          )}/overview`}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Edit Kount Overview',
    key: 'anti-fraud-service.kount-overview',
    path: `${CONNECTION_ANTI_FRAUD_KOUNT_SERVICE}/overview`,
    component: KountOverview,
    menu: false,
  },
  {
    title: 'Edit Kount Credentials',
    key: 'anti-fraud-service.kount-credentials',
    path: `${CONNECTION_ANTI_FRAUD_KOUNT_SERVICE}/credentials`,
    component: KountCredentials,
    menu: false,
  },
  {
    title: 'Edit Kount Manual Reviews',
    key: 'anti-fraud-service.kount-manual-reviews',
    path: `${CONNECTION_ANTI_FRAUD_KOUNT_SERVICE}/manual-reviews`,
    component: KountManualReviews,
    menu: false,
  },
  {
    title: 'Edit Simulator Service',
    key: 'anti-fraud-service.simulator',
    path: CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE,
    component: function SimulatorRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={`${pathTo.editConnectionAntiFraudSimulatorService(
            merchantAccountId,
            id
          )}/overview`}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Edit Simulator Overview',
    key: 'anti-fraud-service.simulator-overview',
    path: `${CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE}/overview`,
    component: SimulatorOverview,
    menu: false,
  },
  {
    title: 'Edit Simulator Credentials',
    key: 'anti-fraud-service.simulator-credentials',
    path: `${CONNECTION_ANTI_FRAUD_SIMULATOR_SERVICE}/credentials`,
    component: SimulatorCredentials,
    menu: false,
  },
  {
    title: 'Edit Payment Service Overview',
    key: 'payment-services.edit-overview',
    path: `${CONNECTION_PAYMENT_SERVICE}/overview`,
    component: PaymentServiceOverviewPage,
    menu: false,
  },
  {
    title: 'Edit Payment Service Credentials',
    key: 'payment-services.edit-credentials',
    path: `${CONNECTION_PAYMENT_SERVICE}/credentials`,
    component: PaymentServiceCredentialsPage,
    menu: false,
  },
  {
    title: 'Edit Payment Service Region Details',
    key: 'payment-services.edit-credentials',
    path: `${CONNECTION_PAYMENT_SERVICE}/region-details`,
    component: PaymentServiceRegionDetailsPage,
    menu: false,
  },
  {
    title: 'Edit Payment Service 3-D Secure',
    key: 'payment-services.edit-credentials',
    path: `${CONNECTION_PAYMENT_SERVICE}/3-d-secure`,
    component: PaymentService3DSecurePage,
    menu: false,
  },
  {
    title: 'Edit Apple Pay',
    key: 'digitalwallet.apple.edit',
    path: `${CONNECTION_APPLE_PAY}`,
    component: function ApplePayRedirect() {
      const { id, merchantAccountId } = useParams() as {
        id: string
        merchantAccountId: string
      }
      return (
        <Navigate
          to={pathTo.editConnectionApplePayOverview(merchantAccountId, id)}
          replace={true}
        />
      )
    },
    menu: false,
  },
  {
    title: 'Apple Pay',
    key: 'digitalwallet.apple.overview',
    path: `${CONNECTION_APPLE_PAY}/overview`,
    component: ApplePayOverview,
    menu: false,
  },
  {
    title: 'Apple Pay',
    key: 'digitalwallet.apple.certificates',
    path: `${CONNECTION_APPLE_PAY}/certificates`,
    component: ApplePayCertificates,
    menu: false,
  },
  {
    title: 'Apple Pay',
    key: 'digitalwallet.apple.domains',
    path: `${CONNECTION_APPLE_PAY}/domains`,
    component: ApplePayDomains,
    menu: false,
  },
  {
    title: 'Edit Google Pay',
    key: 'digitalwallet.google.edit',
    path: CONNECTION_GOOGLE_PAY,
    component: GooglePayOverview,
    menu: false,
  },
  {
    title: 'Edit Click To Pay',
    key: 'digitalwallet.clicktopay.edit',
    path: CONNECTION_CLICK_TO_PAY,
    component: ClickToPayOverview,
    menu: false,
  },
  {
    title: 'Add Gift Card Service',
    key: 'gift-card-services.add',
    path: CONNECTION_GIFT_CARD_SERVICE,
    component: GiftCardServiceAddPage,
    menu: false,
  },
  {
    title: 'Edit Gift Card Service Overview',
    key: 'gift-card-services.edit-overview',
    path: `${CONNECTION_GIFT_CARD_SERVICE}/overview`,
    component: GiftCardServiceOverviewPage,
    menu: false,
  },
  {
    title: 'Edit Gift Card Service Credentials',
    key: 'gift-card-services.edit-credentials',
    path: `${CONNECTION_GIFT_CARD_SERVICE}/credentials`,
    component: GiftCardServiceCredentialsPage,
    menu: false,
  },
  {
    title: 'Connections',
    longTitle: 'Connections',
    key: 'connections.configured',
    path: CONNECTIONS_CONFIGURED,
    component: ConnectionsConfiguredPage,
    menu: false,
  },
  {
    title: 'Connections',
    key: 'connections.catalog',
    path: CONNECTIONS_CATALOG,
    component: ConnectionsCatalogPage,
    menu: false,
  },
  {
    title: 'Connections',
    longTitle: 'Connections',
    key: 'connections',
    path: CONNECTIONS_PATH,
    component: function Default() {
      const { merchantAccountId } = useParams() as { merchantAccountId: string }

      return (
        <Navigate
          replace
          to={pathTo.connectionsConfigured(merchantAccountId)}
        />
      )
    },
    menu: true,
    resource: Resource.connections,
  },
]
