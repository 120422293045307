import { PercentageBadge } from 'home/components/PercentageBadge'
import { LineChartProps } from 'home/constants/config'
import { HomeFilters } from 'home/constants/filter'
import { useTotalTransactions } from 'home/hooks/use-total-transactions'
import { useTotalVolume } from 'home/hooks/use-total-volume'
import {
  HealthDashboardVolumeSummary,
  HealthDashboardTransactionsSummary,
} from 'home/services/health-dashboard'
import currencyFormat from 'shared/helpers/currency-format'

export type FilterBy = 'volume' | 'transactions'
export type Tab = 'volume' | 'transactions' | 'authRate' | 'refunds'

export const useHealthDashboard = (filters: Partial<HomeFilters>) => {
  const {
    totalVolume,
    isLoadingTotalVolume,
    isErrorTotalVolume,
    volumeSeries,
    volumeDateRange,
  } = useTotalVolume(filters)

  const {
    totalTransactions,
    isLoadingTotalTransactions,
    isErrorTotalTransactions,
    transactionsSeries,
    transactionsDateRange,
  } = useTotalTransactions(filters)

  const getTriggerProps = (
    value: Tab,
    summary:
      | HealthDashboardVolumeSummary
      | HealthDashboardTransactionsSummary
      | undefined
  ) => {
    const transactionsSummary =
      !!summary && 'transactionsAccepted' in summary ? summary : undefined
    const volumeSummary =
      !!summary && 'transactionsAuthorized' in summary ? summary : undefined

    return {
      volume: {
        title: 'Volume',
        amount: currencyFormat(volumeSummary?.transactionsAuthorized || 0, {
          currency: filters.currency,
        }),
        value: 'volume',
        trend:
          typeof volumeSummary?.transactionsAuthorizedDeltaPct === 'number' ? (
            <PercentageBadge
              percentage={volumeSummary?.transactionsAuthorizedDeltaPct}
              variant={
                volumeSummary?.transactionsAuthorizedDeltaPct < 0
                  ? 'negative'
                  : 'positive'
              }
              direction={
                volumeSummary.transactionsAuthorizedDeltaPct < 0 ? 'down' : 'up'
              }
            />
          ) : (
            <></>
          ),
        disabled: isLoadingTotalVolume || isErrorTotalVolume,
        loading: isLoadingTotalVolume,
      },
      transactions: {
        title: 'Transactions',
        amount: String(transactionsSummary?.transactionsAccepted || 0),
        value: 'transactions',
        trend:
          typeof transactionsSummary?.transactionsAcceptedDeltaPct ===
          'number' ? (
            <PercentageBadge
              percentage={transactionsSummary?.transactionsAcceptedDeltaPct}
              variant={
                transactionsSummary?.transactionsAcceptedDeltaPct < 0
                  ? 'negative'
                  : 'positive'
              }
              direction={
                transactionsSummary?.transactionsAcceptedDeltaPct < 0
                  ? 'down'
                  : 'up'
              }
            />
          ) : (
            <></>
          ),
        disabled: isLoadingTotalTransactions || isErrorTotalTransactions,
        loading: isLoadingTotalTransactions,
      },
      authRate: {
        title: 'Auth rate',
        amount:
          typeof summary?.transactionsAuthorizedRate === 'number'
            ? `${summary.transactionsAuthorizedRate}%`
            : '-',
        value: 'authRate',
        trend:
          typeof summary?.transactionsAuthorizedRateDelta === 'number' &&
          summary.transactionsAuthorizedRateDelta !== 0 ? (
            <PercentageBadge
              percentage={summary.transactionsAuthorizedRateDelta}
              variant={
                summary.transactionsAuthorizedRateDelta < 0
                  ? 'negative'
                  : 'positive'
              }
              direction={
                summary.transactionsAuthorizedRateDelta < 0 ? 'down' : 'up'
              }
            />
          ) : (
            <></>
          ),
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
      },
      refunds: {
        title: 'Refunds',
        amount:
          filters.filterBy !== 'transactions'
            ? currencyFormat(summary?.refunds || 0, {
                currency: filters.currency,
              })
            : summary?.refunds || 0,
        value: 'refunds',
        trend:
          typeof summary?.refundsDeltaPct === 'number' ? (
            <PercentageBadge
              percentage={summary.refundsDeltaPct}
              variant={summary.refundsDeltaPct < 0 ? 'positive' : 'negative'}
              direction={summary.refundsDeltaPct < 0 ? 'down' : 'up'}
            />
          ) : (
            <></>
          ),
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
      },
    }[value]
  }

  const getLineChartData = (filterBy: FilterBy, tab: Tab): LineChartProps =>
    ({
      volume: {
        data: volumeDateRange,
        series: volumeSeries.volume,
        loading: isLoadingTotalVolume,
      },
      transactions: {
        data: transactionsDateRange,
        series: transactionsSeries.transactions,
        loading: isLoadingTotalTransactions,
      },
      authRate: {
        data: volumeDateRange,
        series:
          filterBy !== 'transactions'
            ? volumeSeries.authRate
            : transactionsSeries.authRate,
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
        numberFormat: 'percent' as const,
      },
      refunds: {
        data: volumeDateRange,
        series:
          filterBy !== 'transactions'
            ? volumeSeries.refunds
            : transactionsSeries.refunds,
        loading: isLoadingTotalVolume || isLoadingTotalTransactions,
      },
    })[tab]

  const filterBy =
    !!filters.filterBy && ['volume', 'transactions'].includes(filters.filterBy)
      ? (filters.filterBy as FilterBy)
      : 'volume'

  return {
    summary:
      filterBy === 'volume' ? totalVolume?.summary : totalTransactions?.summary,
    getTriggerProps,
    getLineChartData,
    filterBy,
  }
}
