import { Heading } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Link } from 'shared/components/Link'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { Flow } from 'shared/constants'
import { pathToFlow } from 'shared/paths'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import {
  Transaction,
  TransactionFlow,
  TransactionFlowId,
} from 'transactions/services'
import { ConnectorRouting } from './ConnectorRouting'
import styles from './TransactionFlowPanel.module.scss'

export const OtherTransactionFlowPanel = ({
  actions,
  transaction,
}: {
  actions: Record<TransactionFlowId, TransactionFlow>
  transaction?: Transaction
}) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Transaction flow</Heading>
        <RestrictAccess
          resource={Resource.flows}
          accessLevel={AccessLevel.read}
        >
          <Link
            href={pathToFlow.flow(merchantAccountId, Flow.redirectTransactions)}
          >
            Go to flow
          </Link>
        </RestrictAccess>
      </Panel.Header>
      <Panel.Content>
        <Summary inline className={styles.flow}>
          <Summary.Key>Routing</Summary.Key>
          <Summary.Value>
            {actions['route-transaction'] && (
              <ConnectorRouting
                transaction={transaction}
                transactionFlow={actions['route-transaction']}
              />
            )}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
