import {
  Badge,
  Box,
  Dropdown,
  Flex,
  Icon,
  Tag,
  Text,
} from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { ColumnDef, DataTable } from 'shared/components/DataTable'
import { CollectionResponse } from 'shared/services/client'
import { MerchantAccount } from 'shared/services/merchant-accounts'
import {
  AddVaultForwardConfig,
  RemoveVaultForwardConfig,
  VaultForwardConfig,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'

interface EditMerchantAccountVaultForwardingPageProps {
  title: string
  configs: VaultForwardConfig[]
  definitions: Partial<CollectionResponse<VaultForwardDefinition>>
  merchantAccount: MerchantAccount
  enable: UseMutationResult<
    VaultForwardConfig,
    any,
    AddVaultForwardConfig,
    unknown
  >
  disable: UseMutationResult<void, unknown, RemoveVaultForwardConfig, unknown>
}

const EditMerchantAccountVaultForwardingPage = ({
  title,
  configs,
  definitions,
  merchantAccount,
  enable,
  disable,
}: EditMerchantAccountVaultForwardingPageProps) => {
  const find = (
    definition: VaultForwardDefinition
  ): VaultForwardConfig | undefined =>
    configs.find((config) => config?.definitionId === definition?.id)

  const columns: Array<ColumnDef<VaultForwardDefinition>> = [
    {
      header: 'Name',
      accessorKey: 'displayName',
      size: 80,
      cell: ({ row }) => <Text>{row.original.displayName}</Text>,
    },
    {
      header: 'Endpoints',
      accessorKey: 'hosts',
      size: 180,
      cell: ({ row }) => (
        <Flex gap={8}>
          {row.original.hosts.map((host) => (
            <Tag variant="subtle" key={host}>
              {new URL(host).hostname}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      header: 'State',
      size: 45,
      cell: ({ row }) => {
        const config = find(row.original)
        return config ? (
          <Badge variant="subtle" color="positive">
            Active
          </Badge>
        ) : (
          <Badge variant="subtle" color="neutral">
            Inactive
          </Badge>
        )
      },
    },
    {
      id: 'actions',
      accessorKey: 'id',
      size: 45,
      header: () => <Box textAlign="center">Actions</Box>,
      cell: function Actions({ row }) {
        const navigate = useNavigate()
        const config = find(row.original)

        return (
          <Box textAlign="center">
            <Dropdown>
              <Dropdown.Trigger asChild>
                <Dropdown.Button variant="tertiary" size="small">
                  <Icon name="more-horizontal" />
                </Dropdown.Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                {!!row.original?.authentications?.length && config && (
                  <Dropdown.Item
                    opensInModal
                    onSelect={() =>
                      navigate(
                        pathTo.vaultForwardingAuthentication(
                          merchantAccount.id,
                          config.id
                        )
                      )
                    }
                  >
                    Authentication methods
                  </Dropdown.Item>
                )}
                {config ? (
                  <Dropdown.Item
                    onSelect={() =>
                      disable.mutate({
                        merchantAccountId: merchantAccount.id,
                        id: config.id,
                      })
                    }
                  >
                    Disable
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onSelect={() =>
                      enable.mutate({
                        merchantAccountId: merchantAccount.id,
                        definitionId: row.original.id,
                      })
                    }
                  >
                    Enable
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          </Box>
        )
      },
    },
  ]

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={[
        { title: 'Merchants', url: pathTo.merchants },
        { title: merchantAccount.displayName },
        { title: 'Vault Forwarding' },
      ]}
    >
      <DataTable data={definitions} columns={columns} />
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountVaultForwardingPage
