import { Flex, Heading } from '@gr4vy/poutine-react'
import { clsx } from 'clsx'
import { Filters, useFilters } from 'shared/hooks'
import { Select, SelectProps } from './Select'
import styles from './Selector.module.scss'

export interface SelectorFilters extends Filters {
  filterBy: string
  currency: string
  period: string
}

export interface SelectorProps {
  disabled?: boolean
  options: SelectProps['options']
}

export const Selector = ({ disabled, options }: SelectorProps) => {
  const [filters, setFilters] = useFilters<SelectorFilters>({
    currency: 'USD',
    filterBy: 'volume',
    period: '7-days',
  })

  return (
    <Flex alignItems="center">
      <Heading level={4} className={clsx(disabled && styles.disabled)}>
        Showing{' '}
      </Heading>
      <Select
        value={filters.filterBy}
        onSelect={(filterBy) =>
          setFilters({
            ...filters,
            ...(filterBy === 'volume' && { tab: 'volume' }),
            ...(filterBy === 'transactions' && { tab: 'transactions' }),
            filterBy,
            currency:
              filterBy === 'transactions' ? undefined : filters.currency,
          })
        }
        options={[
          {
            label: 'Volume',
            value: 'volume',
          },
          {
            label: 'Transactions',
            value: 'transactions',
          },
        ]}
        disabled={disabled}
      />
      {filters.filterBy === 'volume' && (
        <>
          <Heading level={4} className={clsx(disabled && styles.disabled)}>
            {' '}
            processed in{' '}
          </Heading>
          <Select
            value={filters.currency}
            onSelect={(currency) => setFilters({ ...filters, currency })}
            options={[
              {
                label: 'Currency used in transaction',
                value: 'currency',
                disabled: true,
              },
              ...options,
            ]}
            disabled={disabled}
          />
        </>
      )}
      <Heading level={4} className={clsx(disabled && styles.disabled)}>
        {' '}
        for the{' '}
      </Heading>
      <Select
        value={filters.period || '7-days'}
        onSelect={(period) => setFilters({ ...filters, period })}
        optionLabelProp="label"
        lowerCaseLabel
        options={[
          {
            label: 'Last 24 hours',
            value: '1-day',
          },
          {
            label: 'Last 7 days',
            value: '7-days',
          },
          {
            label: 'Last 30 days',
            value: '30-days',
          },
        ]}
        disabled={disabled}
      />
    </Flex>
  )
}
