import { useReportsPermission } from 'reports/hooks/use-reports-permission'
import { useSession } from 'session'
import {
  pathToFlow,
  pathToHome,
  pathToReport,
  pathToTransaction,
} from 'shared/paths'
import { AccessLevel, Resource } from 'shared/permissions/constants'
import { useResourcePermissions } from './use-resource-permissions'

export const useUserHomepage = (merchantAccountId: string) => {
  const { isStaff } = useSession()
  const [
    transactionsAccess,
    flowsAccess,
    reportsAccess,
    healthDashboardAccess,
  ] = useResourcePermissions(
    [
      Resource.transactions,
      Resource.flows,
      Resource.reports,
      Resource.healthDashboard,
    ],
    AccessLevel.read
  )
  const { hasWritePermission } = useReportsPermission()

  if (!merchantAccountId) {
    return '/'
  }

  const reportsHomePage = hasWritePermission
    ? pathToReport.allReports(merchantAccountId)
    : pathToReport.generatedReports(merchantAccountId)

  if (healthDashboardAccess && isStaff) {
    return pathToHome.healthDashboard(merchantAccountId)
  }

  if (transactionsAccess) {
    return pathToTransaction.transactions(merchantAccountId)
  }

  if (flowsAccess) {
    return pathToFlow.flows(merchantAccountId)
  }

  if (reportsAccess) {
    return reportsHomePage
  }

  return '/'
}
