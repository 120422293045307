import { Heading, Stack, Flex, Box, Text } from '@gr4vy/poutine-react'
import { TooltipComponentFormatterCallbackParams } from 'echarts'
import { ConfigProps } from 'home/constants/config'
import { formatAmount } from 'home/helpers/format'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'
import { CloseCircleIcon } from '../CloseCircleIcon'
import styles from './TooltipContent.module.scss'

export type TooltipContentProps = {
  params: Extract<TooltipComponentFormatterCallbackParams, Array<any>>
  numberFormat: ConfigProps['numberFormat']
}

export const TooltipContent = ({
  params,
  numberFormat,
}: TooltipContentProps) => {
  return (
    <Stack gap={8}>
      <Flex justifyContent="space-between" gap={32}>
        <Heading level={5} as="h5">
          {dateFormat(params[0].name, getCurrentLocale(), {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'UTC',
          })}
        </Heading>
        <Box
          className={styles.close}
          data-action="close-tooltip"
          color="gray80"
        >
          <CloseCircleIcon />
        </Box>
      </Flex>
      {params.map((param) => (
        <Flex key={param.seriesId} justifyContent="space-between" gap={16}>
          <Flex gap={4}>
            {param.marker && (
              <span dangerouslySetInnerHTML={{ __html: param.marker }} />
            )}
            <Text variant="med3">{param.seriesName}</Text>
          </Flex>
          <Text variant="med3">
            {formatAmount(Number(param.value), numberFormat)}
          </Text>
        </Flex>
      ))}
    </Stack>
  )
}
