import { isSameDay } from 'date-fns'
import dayjs from 'dayjs'
import { Connection, PaymentService } from 'connections/services'
import { ConfigProps } from 'home/constants/config'
import { ConfiguredConnection } from 'home/constants/connection'
import { currencies } from 'shared/helpers/currency'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'

export const formatAmount = (
  value: number,
  numberFormat?: ConfigProps['numberFormat']
): string =>
  isNaN(value)
    ? '-'
    : new Intl.NumberFormat(getCurrentLocale(), {
        style: numberFormat,
        ...(numberFormat !== 'percent' && {
          minimumSignificantDigits: 1,
          maximumSignificantDigits: 3,
          notation: 'compact',
        }),
      }).format(value / 100)

export const YAxisFormatter = (
  value: number,
  numberFormat?: ConfigProps['numberFormat']
) => (value <= 1 ? String(value) : formatAmount(value, numberFormat))

export const formatLabel = (dateTime: string, length: number) => {
  const sameDay = isSameDay(dateTime, new Date().toISOString())

  if (length >= 30) {
    if (sameDay) {
      return (
        dateFormat(dateTime, getCurrentLocale(), {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'UTC',
        }) + ' (today)'
      )
    }

    const [date, time] = dateFormat(dateTime, getCurrentLocale(), {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    })
      .replace(',', '')
      .split(' ')

    return `${time} ${date}`
  }

  const suffix = sameDay ? ' - today' : ' - yesterday'

  return (
    dateFormat(dateTime, getCurrentLocale(), {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    }) + suffix
  )
}

export const formatCurrencies = (statusCurrencies: string[] | undefined) =>
  currencies
    .filter((currency) => (statusCurrencies || ['USD']).includes(currency.code))
    .map((currency) => ({
      label: `${currency.code} - ${currency.currency}`,
      value: currency.code,
    }))

export const formatTimestampLabel = (timestamp?: string) => {
  if (!timestamp) {
    return { date: null, time: null }
  }

  const differenceInDays = dayjs(new Date()).diff(timestamp, 'day')
  const [date, time] = dateFormat(timestamp, getCurrentLocale(), {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  })
    .replace(',', '')
    .split(' ')

  if (differenceInDays === 0) {
    return { date: 'today', time }
  }

  if (differenceInDays === 1) {
    return { date: 'yesterday', time }
  }

  return { date, time }
}

interface ConfiguredConnectionProps {
  connections?: Connection[]
  paymentServices?: PaymentService[]
  currency?: string
}

const getAcceptedCurrenciesById = (paymentServices: PaymentService[]) =>
  paymentServices.reduce(
    (acc: Record<string, string[]>, paymentService) => ({
      ...acc,
      [paymentService.id]: paymentService.acceptedCurrencies,
    }),
    {}
  )

export const getConfiguredConnections = ({
  connections = [],
  paymentServices = [],
  currency,
}: ConfiguredConnectionProps) => {
  const acceptedCurrenciesById = getAcceptedCurrenciesById(paymentServices)

  let sortedConnections = connections.reduce(
    (acc, connection) => {
      const canAcceptCurrentCurrency =
        !!currency &&
        connection.definition.group.includes('payment-service') &&
        !acceptedCurrenciesById[connection.id]?.includes(currency)

      if (canAcceptCurrentCurrency) {
        return acc
      }

      const configuredConnection = {
        id: connection.id,
        active: connection.active,
        name: connection.name,
        iconUrl: connection.definition.iconUrl,
        acceptedCurrencies: acceptedCurrenciesById[connection.id],
        definitionId: connection.definition.id,
        group: connection.definition.group,
      }

      if (connection.active) {
        acc.active = [...acc.active, configuredConnection]
        return acc
      }

      acc.inactive = [...acc.inactive, configuredConnection]
      return acc
    },
    {
      active: [] as ConfiguredConnection[],
      inactive: [] as ConfiguredConnection[],
    }
  )

  sortedConnections = {
    active: sortedConnections.active.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
    inactive: sortedConnections.inactive.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
  }

  return [...sortedConnections.active, ...sortedConnections.inactive]
}
