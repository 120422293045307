import { Heading } from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { Link } from 'shared/components/Link'
import { Panel } from 'shared/components/Panel'
import { Summary } from 'shared/components/Summary'
import { Flow } from 'shared/constants'
import { pathToFlow } from 'shared/paths'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import {
  Transaction,
  TransactionFlow,
  TransactionFlowId,
} from 'transactions/services'
import { ConnectorRouting } from './ConnectorRouting'
import { ThreeDSecureAction } from './ThreeDSecureAction'
import styles from './TransactionFlowPanel.module.scss'

interface CardTransactionFlowPanelProps {
  actions: Partial<Record<TransactionFlowId, TransactionFlow>>
  transaction?: Transaction
}

export const CardTransactionFlowPanel = ({
  actions,
  transaction,
}: CardTransactionFlowPanelProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const declined = Boolean(actions['decline-early']?.outcome?.result)
  const threeDSecureAction = actions['skip-3ds']

  return (
    <Panel>
      <Panel.Header>
        <Heading level={4}>Transaction flow</Heading>
        <RestrictAccess
          resource={Resource.flows}
          accessLevel={AccessLevel.read}
        >
          <Link
            href={pathToFlow.flow(merchantAccountId, Flow.cardTransactions)}
          >
            Go to flow
          </Link>
        </RestrictAccess>
      </Panel.Header>
      <Panel.Content>
        <Summary inline className={styles.flow}>
          <Summary.Key>Decline</Summary.Key>
          <Summary.Value>{declined ? 'Decline' : 'Continue'}</Summary.Value>
          <Summary.Key>Routing</Summary.Key>
          <Summary.Value>
            {actions['route-transaction'] && (
              <ConnectorRouting
                transaction={transaction}
                transactionFlow={actions['route-transaction']}
              />
            )}
          </Summary.Value>
          <Summary.Key>3D Secure</Summary.Key>
          <Summary.Value>
            {!declined && <ThreeDSecureAction action={threeDSecureAction} />}
          </Summary.Value>
        </Summary>
      </Panel.Content>
    </Panel>
  )
}
