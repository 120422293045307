import { ColorBlue60, ColorGray70 } from '@gr4vy/poutine-tokens'
import { addDays, addHours } from 'date-fns'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { SeriesOption } from 'echarts'
import { defaultSeriesSettings, LineChartProps } from 'home/constants/config'
import { Tab } from 'home/hooks/use-health-dashboard'
import {
  HealthDashboardTransactionsSeries,
  HealthDashboardVolumeSeries,
  Period,
} from 'home/services/health-dashboard'
dayjs.extend(duration)

interface GenerateSeriesProps {
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | undefined
  period: Period
}

export const generateSeries = ({
  series,
  period,
}: GenerateSeriesProps): Record<Tab, SeriesOption[]> => {
  if (series) {
    const currentLabelName = getSeriesNameByPeriod(period).current
    const previousLabelName = getSeriesNameByPeriod(period).previous

    const transactionsCurrent =
      'transactionsAccepted' in series.current ? series.current : undefined
    const transactionsPrevious =
      'transactionsAccepted' in series.previous ? series.previous : undefined
    const volumeCurrent =
      'transactionsAuthorized' in series.current ? series.current : undefined
    const volumePrevious =
      'transactionsAuthorized' in series.previous ? series.previous : undefined

    return {
      volume: [
        {
          name: currentLabelName,
          data: volumeCurrent?.transactionsAuthorized,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: volumePrevious?.transactionsAuthorized,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      transactions: [
        {
          name: currentLabelName,
          data: transactionsCurrent?.transactionsAccepted,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: transactionsPrevious?.transactionsAccepted,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      authRate: [
        {
          name: currentLabelName,
          data: series.current.transactionsAuthorizedRate,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: series.previous.transactionsAuthorizedRate,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
      refunds: [
        {
          name: currentLabelName,
          data: series.current.refunds,
          color: ColorBlue60,
          ...defaultSeriesSettings,
        },
        {
          name: previousLabelName,
          data: series.previous.refunds,
          color: ColorGray70,
          ...defaultSeriesSettings,
        },
      ],
    }
  }

  return {
    volume: [],
    transactions: [],
    authRate: [],
    refunds: [],
  }
}

const generateHourRange = (timestamp: string, hours: number) =>
  Array.from({ length: hours }).map((_, index) =>
    addHours(new Date(timestamp), index).toISOString()
  )

const generateDayRange = (timestamp: string, days: number) =>
  Array.from({ length: days }).map((_, index) =>
    addDays(new Date(timestamp), index).toISOString()
  )

export const generateDateRange = (
  series:
    | HealthDashboardVolumeSeries
    | HealthDashboardTransactionsSeries
    | undefined
): LineChartProps['data'] => {
  if (series?.current.firstInterval) {
    const [timestamp, period] = series.current.firstInterval.split('/')

    const length =
      'transactionsAuthorized' in series.current
        ? series.current.transactionsAuthorized.length
        : series.current.transactionsAccepted.length

    return dayjs.duration(period).get('days') > 0
      ? generateDayRange(timestamp, length)
      : generateHourRange(timestamp, length)
  }

  return []
}

export const getSeriesNameByPeriod = (period: Period) =>
  ({
    '1-day': { current: 'Last 24 hours', previous: 'Previous 24 hours' },
    '7-days': { current: 'Last 7 days', previous: 'Previous 7 days' },
    '30-days': { current: 'Last 30 days', previous: 'Previous 30 days' },
  })[period]
