import { Flex, Text, atoms } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { ArrowDownIcon } from './ArrowDownIcon'
import { ArrowUpIcon } from './ArrowUpIcon'
import styles from './PercentageBadge.module.scss'

export interface PercentageBadgeProps {
  percentage: number
  variant: 'positive' | 'negative'
  direction: 'up' | 'down'
}

export const PercentageBadge = ({
  percentage,
  variant,
  direction,
}: PercentageBadgeProps) => {
  const sign = percentage >= 0 ? '+' : percentage < -500 ? '-' : ''
  const label = Math.abs(percentage) > 500 ? 'over 500' : percentage

  return (
    <Flex
      className={clsx(
        styles.badge,
        atoms({
          color: variant === 'positive' ? 'green90' : 'red90',
        })
      )}
      alignItems="center"
      gap={4}
    >
      <Text as="span" variant="med4">{`${sign}${label}%`}</Text>
      {direction === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </Flex>
  )
}
