import { generatePath } from 'react-router-dom'
export const REPORTS_PATH = '/merchants/:merchantAccountId/reports'
export const ALL_REPORTS_PATH = `${REPORTS_PATH}/all`
export const SCHEDULED_REPORTS_PATH = `${REPORTS_PATH}/scheduled`
export const GENERATED_REPORTS_PATH = `${REPORTS_PATH}/generated`
export const REPORT_DETAILS_PATH = `${REPORTS_PATH}/executions/:id`
export const SCHEDULE_DETAILS_PATH = `${REPORTS_PATH}/schedules/:id`

export const pathTo = {
  allReports: (merchantAccountId: string) =>
    generatePath(ALL_REPORTS_PATH, { merchantAccountId }),
  scheduledReports: (merchantAccountId: string) =>
    generatePath(SCHEDULED_REPORTS_PATH, { merchantAccountId }),
  generatedReports: (merchantAccountId: string) =>
    generatePath(GENERATED_REPORTS_PATH, { merchantAccountId }),
  reportExecutionDetails: (merchantAccountId: string, id: string) =>
    generatePath(REPORT_DETAILS_PATH, { merchantAccountId, id }),
  reportScheduleDetails: (merchantAccountId: string, id: string) =>
    generatePath(SCHEDULE_DETAILS_PATH, { merchantAccountId, id }),
}
