import { Button, Drawer, Flex, Stack, Text } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  AddVaultForwardConfigAuthentication,
  VaultForwardConfig,
  VaultForwardConfigAuthentication,
  VaultForwardConfigAuthenticationField,
  VaultForwardDefinition,
} from 'shared/services/vault-forward'
import { AddAuthenticationDrawerMethodFields } from './AddAuthenticationDrawerMethodFields'

type FormValues = Pick<
  VaultForwardConfigAuthentication,
  'displayName' | 'kind' | 'fields'
>

export type AddAuthenticationDrawerProps = {
  open: boolean
  onClose: () => void
  create: UseMutationResult<
    VaultForwardConfigAuthentication,
    any,
    AddVaultForwardConfigAuthentication,
    unknown
  >
  definition?: VaultForwardDefinition
  config?: VaultForwardConfig
}

export const AddAuthenticationDrawer = ({
  open,
  onClose,
  create,
  definition,
  config,
}: AddAuthenticationDrawerProps) => {
  const [form] = Form.useForm<FormValues>()
  const kind = Form.useWatch('kind', form)
  const { isPending, isSuccess } = create

  const onCancel = () => {
    form.resetFields()
    create.reset()
    onClose()
  }

  const handleCreate = (values: FormValues) => {
    if (!config) {
      return
    }
    const { merchantAccountId, id } = config
    const { fields, kind, ...rest } = values
    const normalizedFields = fields.map((field) =>
      Object.entries(field).reduce((_, [key, value]) => ({ key, value }), {})
    ) as VaultForwardConfigAuthenticationField[]
    create.mutate({
      merchantAccountId,
      id,
      kind,
      fields: normalizedFields,
      ...rest,
    })
  }

  const updateFieldValue = (field: any, value: string) => {
    form.setFieldValue(field, value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onCancel, [isSuccess])

  return (
    <Drawer
      open={open}
      title="Add an authentication method"
      footer={
        <Flex gap={16} justifyContent="flex-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            form="add-authentication"
            disabled={isPending}
            loading={isPending}
            loadingText="Adding"
          >
            Add
          </Button>
        </Flex>
      }
      onClose={onCancel}
    >
      <Stack gap={32}>
        <Stack gap={8}>
          <Text variant="reg3" color="gray90">
            Before you can use Vault Forwarding, you need to activate and
            configure each specific API endpoint that you want to connect to.
          </Text>
        </Stack>
        <Form
          name="add-authentication"
          form={form}
          onFinish={handleCreate}
          autoComplete="off"
          layout={'vertical'}
          requiredMark={false}
        >
          <Stack gap={24}>
            <FormItem
              name="displayName"
              label={<Label>Name</Label>}
              required
              rules={[
                { required: true, message: 'Enter authentication name.' },
                {
                  type: 'string',
                  max: 255,
                  message: 'Enter a maximum of 255 characters.',
                },
              ]}
            >
              <Input autoFocus />
            </FormItem>
            <FormItem
              name="kind"
              label={<Label>Authentication method</Label>}
              required
              rules={[
                { required: true, message: 'Select authentication method' },
              ]}
            >
              <Select
                placeholder="Select a method"
                dropdownStyle={{ pointerEvents: 'auto' }}
                aria-label="Select a method"
              >
                {definition?.authentications.map(({ title, kind }) => (
                  <Select.Option key={kind} value={kind}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <AddAuthenticationDrawerMethodFields
              fields={
                definition?.authentications?.find(
                  (authentication) => authentication.kind === kind
                )?.fields
              }
              updateFieldValue={updateFieldValue}
            />
          </Stack>
        </Form>
      </Stack>
    </Drawer>
  )
}
