import { ColorGray20, ColorGray90 } from '@gr4vy/poutine-tokens'
import { UseEChartsOptions } from '@kbox-labs/react-echarts'
import { SeriesOption } from 'echarts'
import { ConfigProps, gridConfig, axisLineConfig } from 'home/constants/config'
import { formatLabel, YAxisFormatter } from './format'
import { tooltipFormatter } from './line-chart'

export const getLegendData = (series: SeriesOption[] = []) =>
  series.filter((serie) => serie.name).map((serie) => serie.name as string)

export const generateConfig = ({
  data = [],
  series = [],
  tooltipRef,
  showTooltip,
  numberFormat,
}: ConfigProps): UseEChartsOptions => ({
  tooltip: {
    formatter: (params) => tooltipFormatter(params, numberFormat),
    appendTo: tooltipRef || document.body,
    trigger: 'axis',
    triggerOn: 'click',
    enterable: true,
    alwaysShowContent: showTooltip,
    axisPointer: {
      show: true,
      triggerOn: 'click',
    },
  },
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        fontFamily: 'Inter',
        formatter: (value: number) => YAxisFormatter(value, numberFormat),
        color: ColorGray90,
        margin: 4,
      },
      splitLine: {
        lineStyle: {
          color: ColorGray20,
        },
      },
      splitNumber: 2,
      ...axisLineConfig,
    },
    {
      ...axisLineConfig,
    },
  ],
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data,
    animation: false,
    splitLine: {
      interval: (_, value) => new Date(value).getUTCHours() === 0,
      show: true,
      lineStyle: {
        color: ColorGray20,
      },
    },
    axisLabel: {
      color: ColorGray90,
      fontFamily: 'Inter',
      align: 'left',
      alignMaxLabel: 'right',
      interval: data.length - 2,
      formatter: (value) => formatLabel(value, data.length),
    },
    axisTick: {
      show: false,
    },
    ...axisLineConfig,
  },
  series,
  legend: {
    data: getLegendData(series),
    orient: 'horizontal',
    icon: 'circle',
    bottom: 8,
    itemWidth: 8,
    left: 30,
    textStyle: {
      fontFamily: 'Inter',
    },
  },
  useUTC: true,
  ...gridConfig,
})
