import * as RadixTabs from '@radix-ui/react-tabs'
import { PropsWithChildren } from 'react'
import { Content } from './Content'
import { List } from './List'
import { Trigger } from './Trigger'

export type TabsProps = PropsWithChildren<RadixTabs.TabsProps>

export const Tabs = ({ children, ...rest }: TabsProps) => {
  return (
    <RadixTabs.Root orientation="vertical" {...rest}>
      {children}
    </RadixTabs.Root>
  )
}

Tabs.List = List
Tabs.Trigger = Trigger
Tabs.Content = Content
