import { Flex, Stack } from '@gr4vy/poutine-react'
import { GettingStartedBanner } from 'admin/components/GettingStartedBanner'
import { Selector } from 'home/components/Selector'
import { TimestampLabel } from 'home/components/TimestampLabel'
import { formatTimestampLabel } from 'home/helpers/format'
import { useStatus } from 'home/hooks/use-status'

export interface TopSectionProps {
  showGettingStarted: boolean
  hasTransactions: boolean
}

export const TopSection = ({
  showGettingStarted,
  hasTransactions,
}: TopSectionProps) => {
  const { status, isLoading, isError } = useStatus()

  const { date, time } = formatTimestampLabel(
    status?.latestExecution?.timestamp
  )

  return (
    <Stack flexDirection="column" gap={40}>
      {showGettingStarted && (
        <GettingStartedBanner hasTransactions={hasTransactions} />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Selector
          options={status?.currencies || [{ value: 'USD', label: 'USD' }]}
          disabled={
            isLoading ||
            isError ||
            (showGettingStarted && !hasTransactions) ||
            !status?.currencies
          }
        />
        {(!showGettingStarted || (showGettingStarted && hasTransactions)) &&
          !isError && <TimestampLabel date={date} time={time} />}
      </Flex>
    </Stack>
  )
}
