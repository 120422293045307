import { ColorGray20, ColorGray90 } from '@gr4vy/poutine-tokens'
import { UseEChartsOptions } from '@kbox-labs/react-echarts'
import { startOfHour, subDays } from 'date-fns'
import { LineSeriesOption, SeriesOption } from 'echarts'
import { formatLabel } from 'home/helpers/format'

export type ConfigProps = {
  currency?: string
  data:
    | Array<{
        value: string | number
      }>
    | Array<string | number>
  series: SeriesOption[]
  tooltipRef: HTMLDivElement | null
  showTooltip: boolean
  numberFormat?: 'decimal' | 'percent'
}

export interface LineChartProps
  extends Omit<ConfigProps, 'showTooltip' | 'tooltipRef'> {
  loading: boolean
}

export const gridConfig = {
  grid: {
    top: 6,
    right: 1,
    left: 35,
    containLabel: false,
  },
}

export const axisLineConfig = {
  axisLine: {
    show: true,
    lineStyle: {
      color: ColorGray20,
    },
  },
}

export const loadingConfig: UseEChartsOptions = {
  ...gridConfig,
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ColorGray20,
        },
      },
      interval: 10,
      ...axisLineConfig,
    },
    {
      ...axisLineConfig,
    },
  ],
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [{ value: ' ' }],
    animation: false,
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: false,
    },
    ...axisLineConfig,
  },
  series: [],
}

export const gettingStartedConfig: UseEChartsOptions = {
  ...gridConfig,
  yAxis: [
    {
      type: 'value',
      min: 0,
      max: 10,
      splitNumber: 2,
      splitLine: {
        interval: 1,
        lineStyle: {
          color: [ColorGray20, '#fff'],
        },
      },
      axisLabel: {
        fontFamily: 'Inter',
        formatter: '{value}k',
        color: ColorGray90,
      },
      ...axisLineConfig,
    },
    {
      ...axisLineConfig,
    },
  ],
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: Array.from({ length: 14 })
      .map((_, index) =>
        subDays(startOfHour(new Date(new Date())), index).toISOString()
      )
      .reverse(),
    axisTick: {
      show: false,
    },
    splitLine: {
      interval: 2,
      show: true,
    },
    axisLabel: {
      fontFamily: 'Inter',
      align: 'left',
      alignMaxLabel: 'right',
      interval: 14 - 2,
      formatter: (value) => formatLabel(value, 30),
      color: ColorGray90,
    },
    ...axisLineConfig,
  },
  series: [],
  graphic: {
    type: 'group',
    left: 'center',
    top: 120,
    children: [
      {
        z: 1,
        type: 'text',
        cursor: 'default',
        style: {
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: 12,
          lineHeight: 18,
          align: 'center',
          text: 'Warming up to something big.',
        },
      },
      {
        type: 'text',
        cursor: 'default',
        z: 1,
        style: {
          padding: 24,
          fontFamily: 'Inter',
          fontSize: 12,
          lineHeight: 18,
          align: 'center',
          overflow: 'break',
          width: 230,
          text: 'Once transactions start coming through you will see their volume here.',
        },
      },
    ],
  },
}

export const defaultSeriesSettings: LineSeriesOption = {
  type: 'line',
  showSymbol: false,
  animation: false,
  lineStyle: {
    width: 1,
  },
}
