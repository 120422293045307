import { Dropdown, Text, atoms } from '@gr4vy/poutine-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Option } from './Option'
import styles from './Select.module.scss'

export interface SelectProps {
  options: Array<{ label: string; value: string; disabled?: boolean }>
  value?: string
  optionLabelProp?: 'value' | 'label'
  lowerCaseLabel?: boolean
  onSelect?: (value: string) => void
  disabled?: boolean
}

export const Select = ({
  options,
  value,
  lowerCaseLabel,
  optionLabelProp = 'value',
  onSelect,
  disabled,
}: SelectProps) => {
  const defaultValue =
    value || options.find((option) => !option.disabled)?.value
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const handleSelect = (value: string) => {
    setSelectedValue(value)
    onSelect?.(value)
  }

  const getLabel = () => {
    if (optionLabelProp === 'value') {
      return selectedValue
    }

    const option = options.find((option) => option.value === selectedValue)
    let label = option?.label
    if (!option && !!options.length) {
      setSelectedValue(options[0].value)
      label = options[0].label
    }
    return lowerCaseLabel ? label?.toLowerCase() : label
  }

  useEffect(() => setSelectedValue(defaultValue), [defaultValue])

  return (
    <Dropdown modal={false}>
      <Dropdown.Trigger disabled={disabled} asChild>
        <Dropdown.Button variant="tertiary" className={styles.trigger}>
          <Text
            fontSize="xl"
            className={clsx(disabled && atoms({ color: 'gray60' }))}
          >
            {getLabel()}
          </Text>
        </Dropdown.Button>
      </Dropdown.Trigger>
      {!!options.length && (
        <Dropdown.Content align="start">
          {options.map((option, index) => (
            <Option
              key={index}
              onSelect={() => handleSelect(option.value)}
              data-active={
                option.value === selectedValue ||
                (selectedValue && option.label.match(selectedValue))
              }
              disabled={option.disabled}
            >
              <Text variant={option.disabled ? 'reg4' : 'none'}>
                {option.label}
              </Text>
            </Option>
          ))}
        </Dropdown.Content>
      )}
    </Dropdown>
  )
}
