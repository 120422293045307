import client, { CollectionResponse } from 'shared/services/client'

export type VaultForwardConfig = {
  type: string
  id: string
  merchantAccountId: string
  definitionId: string
  displayName: string
  hosts: string[]
  authentications: VaultForwardConfigAuthentication[]
}

export type VaultForwardConfigAuthenticationField = {
  key: string
  displayName: string
  required: boolean
  format: 'text' | 'multiline' | 'file' | 'number'
  secret: boolean
}

export type VaultForwardConfigAuthentication = {
  id: string
  createdAt: string
  updatedAt: string
  kind: 'mtls' | 'mle' | 'signature'
  label: 'Mutual TLS' | 'Message Level Encryption' | 'Signature'
  displayName: string
  fields: VaultForwardConfigAuthenticationField[]
  creator: {
    id: string
    name: string
    emailAddress: string
  }
}

export type VaultForwardDefinition = {
  type: string
  id: string
  displayName: string
  hosts: string[]
  authentications: {
    kind: VaultForwardConfigAuthentication['kind']
    title: VaultForwardConfigAuthentication['label']
    fields: VaultForwardConfigAuthenticationField[]
  }[]
}

export const listVaultForwardConfigs = async (
  merchantAccountId: string
): Promise<CollectionResponse<VaultForwardConfig>> => {
  const response = await client.get('/vault-forward/configs', {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
  })
  return response.data
}

export const listVaultForwardDefinitions = async (
  merchantAccountId: string
): Promise<CollectionResponse<VaultForwardDefinition>> => {
  const response = await client.get('/vault-forward/definitions', {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
  })
  return response.data
}

export const addVaultForwardConfig = async ({
  merchantAccountId,
  definitionId,
}: AddVaultForwardConfig): Promise<VaultForwardConfig> => {
  const response = await client.post(
    '/vault-forward/configs',
    { definitionId },
    {
      headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
    }
  )
  return response.data
}

export const removeVaultForwardConfig = async ({
  merchantAccountId,
  id,
}: RemoveVaultForwardConfig): Promise<void> => {
  return client.delete(`/vault-forward/configs/${id}`, {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
  })
}

export const getVaultForwardConfig = async ({
  merchantAccountId,
  id,
}: GetVaultForwardConfig): Promise<VaultForwardConfig> => {
  const response = await client.get(`/vault-forward/configs/${id}`, {
    headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
  })
  return response.data
}

export const addVaultForwardConfigAuthentication = async ({
  merchantAccountId,
  id,
  ...rest
}: AddVaultForwardConfigAuthentication): Promise<VaultForwardConfigAuthentication> => {
  const response = await client.post(
    `/vault-forward/configs/${id}/authentications`,
    rest,
    {
      headers: { 'X-GR4VY-MERCHANT-ACCOUNT-ID': merchantAccountId },
    }
  )
  return response.data
}

export type AddVaultForwardConfig = Pick<
  VaultForwardConfig,
  'merchantAccountId' | 'definitionId'
>
export type RemoveVaultForwardConfig = Pick<
  VaultForwardConfig,
  'merchantAccountId' | 'id'
>
export type GetVaultForwardConfig = Pick<
  VaultForwardConfig,
  'merchantAccountId' | 'id'
>
export type AddVaultForwardConfigAuthentication = Pick<
  VaultForwardConfig,
  'merchantAccountId' | 'id'
> &
  Pick<VaultForwardConfigAuthentication, 'displayName' | 'kind' | 'fields'>
