import { Flex, Grid, GridItem } from '@gr4vy/poutine-react'
import { LineChart } from 'home/components/LineChart'
import { Tabs } from 'home/components/Tabs'
import { HomeFilters } from 'home/constants/filter'
import { useHealthDashboard } from 'home/hooks/use-health-dashboard'
import { DataVisualizationGettingStarted } from './DataVisualizationGettingStarted'

export interface DataVisualizationProps {
  showGettingStarted: boolean
  filters: Partial<HomeFilters>
  setFilters: (filters: Partial<HomeFilters>) => void
}

export const DataVisualization = ({
  showGettingStarted,
  filters,
  setFilters,
}: DataVisualizationProps) => {
  const { summary, filterBy, getLineChartData, getTriggerProps } =
    useHealthDashboard(filters)

  const tabsValue = filters.tab || filters.filterBy || 'volume'

  return (
    <Flex alignItems="center">
      {showGettingStarted ? (
        <DataVisualizationGettingStarted />
      ) : (
        <Tabs
          value={tabsValue}
          onValueChange={(tab) => setFilters({ ...filters, tab })}
        >
          <Grid>
            <GridItem gridColumn="span 3">
              <Tabs.List>
                {filterBy === 'volume' ? (
                  <Tabs.Trigger {...getTriggerProps('volume', summary)} />
                ) : (
                  <Tabs.Trigger {...getTriggerProps('transactions', summary)} />
                )}
                <Tabs.Trigger {...getTriggerProps('authRate', summary)} />
                <Tabs.Trigger {...getTriggerProps('refunds', summary)} />
              </Tabs.List>
            </GridItem>
            <GridItem gridColumn="span 9">
              <Tabs.Content value={filterBy}>
                {filterBy === 'volume' ? (
                  <LineChart {...getLineChartData(filterBy, 'volume')} />
                ) : (
                  <LineChart {...getLineChartData(filterBy, 'transactions')} />
                )}
              </Tabs.Content>
              <Tabs.Content value="authRate">
                <LineChart {...getLineChartData(filterBy, 'authRate')} />
              </Tabs.Content>
              <Tabs.Content value="refunds">
                <LineChart {...getLineChartData(filterBy, 'refunds')} />
              </Tabs.Content>
            </GridItem>
          </Grid>
        </Tabs>
      )}
    </Flex>
  )
}
